<template>
    <v-form class="email-form" ref="emailForm" v-model="valid" @submit.prevent="sendEmail">
        <v-card elevation="0" color="library">
            <!-- <v-card-title
        class="text-h5 align-center justify-center px-5 purple---text"
        v-text="dialogTitle"
      ></v-card-title> -->

            <v-card-text class="px-5">
                <v-container fluid pa-0 class="email-form-container">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                class="px-4"
                                height="50"
                                v-model="selectedSubject"
                                :single-line="$vuetify.breakpoint.smAndUp"
                                label="Subject"
                                disabled
                                persistent-placeholder
                                hide-details
                                solo-inverted
                                flat
                                :rules="[rules.required]">
                                <template v-slot:prepend-inner v-if="$vuetify.breakpoint.smAndUp">
                                    <span class="prefix-text">Subject:</span>
                                </template></v-text-field
                            >
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                class="px-4"
                                height="50"
                                v-model="model.firstname"
                                single-line
                                label="First Name"
                                placeholder="First Name"
                                persistent-placeholder
                                hide-details
                                required
                                :rules="[rules.required]" />
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field
                                class="px-4"
                                v-model="model.lastname"
                                single-line
                                height="50"
                                label="Last Name"
                                placeholder="Last Name"
                                persistent-placeholder
                                hide-details
                                required
                                :rules="[rules.required]" />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                class="px-4"
                                v-model="model.email"
                                single-line
                                height="50"
                                label="Email"
                                placeholder="Email"
                                persistent-placeholder
                                hide-details
                                required
                                :rules="[rules.required]" />
                        </v-col>

                        <!-- <v-col cols="12" sm="6">
              <v-text-field
                class="px-4"
                v-model="model.phone"
                single-line
                
                
                height="50"
                label="Phone Number"
                placeholder="Phone Number"
                persistent-placeholder
                hide-details
                required
                :rules="[rules.required]"
              />
            </v-col> -->
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                class="px-4 scroll-hide transparent"
                                v-model="model.message"
                                rows="5"
                                no-resize
                                single-line
                                label="Message"
                                placeholder="Message"
                                persistent-placeholder
                                hide-details></v-textarea>
                            <!-- <v-textarea
                class="px-4 "
                v-model="text"
                label="Textarea"
                color="library"
                outlined
                dense
                :style="{ 'background-color': $vuetify.theme.themes.light.library.base }"
              >
              </v-textarea> -->
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-text class="pa-5 text-center text-body-1" v-if="showError" v-html="errorMessage"></v-card-text>

            <v-card-actions class="pa-5 d-flex align-center justify-center">
                <v-btn
                    type="submit"
                    class="flex-grow-1 rounded-lg library--text"
                    height="50"
                    max-width="16%"
                    elevation="0"
                    v-text="'Send'" />
            </v-card-actions>

            <!-- <v-overlay  opacity="0.85" class="p-absolute" v-if="loading">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-overlay> -->

            <v-dialog v-model="sendingEmail" hide-overlay :persistent="loading" width="300">
                <v-card color="library" class="px-4 pt-8 pb-2">
                    <v-card-text v-if="loading">
                        Sending email...
                        <v-progress-linear indeterminate class="mb-0"></v-progress-linear>
                    </v-card-text>
                    <v-card-text v-else class="d-flex flex-column align-center justify-center">
                        <h3 class="pb-3 text" style="text-wrap: nowrap">Email sent!</h3>
                        <v-btn
                            class="rounded-lg"
                            @click="
                                sendingEmail = false;
                                emailSuccess = false;
                            "
                            >Close</v-btn
                        >
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
    </v-form>
</template>

<script>
export default {
    name: "library-form",
    props: {
        selectedSubject: {
            type: String,
        },
    },
    components: {},
    computed: {},
    data() {
        return {
            model: {
                firstname: "",
                lastname: "",
                email: "",
                message: "",
                subject: this.selectedSubject,
            },
            rules: {
                required: (value) => !!value,
            },
            valid: false,
            showError: false,

            errorMessage: "",

            loading: false,
            sendingEmail: false,
            emailSuccess: false,
        };
    },
    methods: {
        async sendEmail() {
            //Fire an email reaction

            console.log("Email Model:", this.model);
            console.log("send email clicked!", this.valid);
            this.sendingEmail = true;

            if (this.valid) {
                this.loading = true;
                const response = await this.$fluro.api
                    .post("/reaction/spark/64226730ecc151066499bf51", this.model)
                    .then((res) => {
                        if (res) this.emailSuccess = true;
                        return res;
                    })
                    .catch((err) => {
                        console.log("ERROR", err);
                        this.errorMessage = err;
                    });
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.v-input input {
    max-height: none !important;
}

.email-form {
    width: 100%;
}
.email-form-container {
    .v-label {
        min-height: 40px;
        @media (max-width: 399px) {
            font-size: large;
        }
        @media (min-width: 400px) {
            font-size: x-large;
        }
    }
    .v-input {
        @media (max-width: 399px) {
            font-size: large;
        }
        @media (min-width: 400px) {
            font-size: x-large;
        }
    }

    .v-input--is-outlined .v-input__control {
        border-color: var(--v-library-base);
    }
}

.prefix-text::after {
    content: "\00a0"; /* Add a non-breaking space character after the prefix text */
}

.transparent {
    background-color: transparent !important;
}
</style>
