<template>
    <div class="book-dialog text-center">
        <v-dialog v-model="dialog" :width="$vuetify.breakpoint.lgAndUp ? '55%' : '90%'" content-class="scroll-hide">
            <template v-slot:activator="{ on, attrs }">
                <div class="p-relative library-book" dark v-bind="attrs" v-on="on">
                    <!-- <img class="mx-auto rounded-sm book-cover" :src="getImage(item.cover)" width="120" /> -->
                    <!-- <v-icon v-if="item.isPremium" class="premium-icon" size="25">mdi-star-circle</v-icon> -->
                    <!-- <img class="mx-auto rounded-sm book-cover" :src="getImage(item.cover)" width="120" /> -->

                    <!-- <v-img
            class="mx-auto p-relative rounded-sm"
            :src="getImage(item.cover)"
            :width="$vuetify.breakpoint.mdAndUp ? '140' : '100'"
            height="auto"
          > -->
                    <v-img
                        class="mx-auto rounded-sm d-flex align-center justify-center"
                        :src="getImage(item.cover)"
                        contain
                        max-width="140"
                        width="100%"
                        eager
                    />
                    <div v-if="lockCondition(item)" class="premium-overlay d-flex align-center justify-center p-absolute">
                        <v-icon color="white" size="50">mdi-crown</v-icon>
                    </div>
                </div>
            </template>

            <v-card class="rounded-xl">
                <!-- <v-card-title class="text-h5 grey lighten-2 text-center">
          {{ item.title }}
        </v-card-title> -->
                <!-- <v-row>
          <v-col cols="12">
            <v-card-text>
              <v-row justify="center" align="center">
                <v-col>
                  <v-img class="mx-auto shadow" :src="getImage(item.cover)" eager contain width="250"></v-img>
                </v-col>
                <v-col>
                  {{ item.summary }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row> -->
                <v-row no-gutters class="d-flex align-center justify-center">
                    <v-col cols="12" class="py-14 px-0 d-flex align-center justify-center">
                        <img class="mx-auto shadow book-cover" style="border-radius: 5px" :src="getImage(item.cover)" width="250" eager />
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters class="d-flex align-center justify-center card-background">
                    <!--card-background-->
                    <v-col cols="12" class="pa-7 text-center" style="font-size: larger" v-html="item.summary"> </v-col>
                    <v-col cols="12">
                        <v-card-actions class="d-flex align-center justify-center pb-8">
                            <!-- <v-spacer></v-spacer> -->
                            <!-- <v-btn color="primary" @click="dialog = false">
                Close
              </v-btn> -->
                            <!-- Link to book reading page here -->
                            <!-- User logged in -->
                            <!-- <v-btn v-if="false" elavation="2" color="white" height="60" class="px-8 primary--text">
                <span class="text-h6 font-weight-bold">Read Now</span>
                <v-icon class="ml-4" size="30">mdi-book-open-page-variant</v-icon>
              </v-btn> -->
                            <v-btn
                                v-if="lockCondition(item)"
                                dark
                                color="library"
                                height="60"
                                class="px-8"
                                @click="$router.push('/account/payment')"
                            >
                                <span class="text-h6 font-weight-bold">Become a Member to Read</span>
                                <!-- <v-icon class="ml-2" size="30">mdi-crown</v-icon> -->
                            </v-btn>
                            <v-btn v-else dark color="secondary" height="60" class="px-8" @click="openBook(item)">
                                <span class="text-h6 font-weight-bold">Read Now</span>
                                <v-icon class="ml-2" size="30">mdi-book-open-page-variant</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import BookDialog from "@/components/library/book-dialog.vue";
export default {
    name: "BookTile",
    data() {
        return {
            dialog: false,
        };
    },
    components: { BookDialog },

    props: ["item"],
    computed: {
        premiumUser() {
            return this.$app?.user?.isPremium;
        },
        freeTrialUser() {
            return this.$app?.user?.freeTrialUser;
        },
    },
    methods: {
        lockCondition(book) {
            //Lock out book if
            //Book is premium && is NOT a premium user
            //OR if user is a FREE TRIAL user && book item is locked if on free trial
            return (book.isPremium && !this.premiumUser) || (book.isLockedForFreeTrial && this.freeTrialUser);
        },

        getImage(image) {
            //   console.log(image);
            const url = `${this.$fluro.asset.imageUrl(image)}&noRedirect=true`;
            // console.log("Attempting to get Book Cover:", url);
            return url;
        },
        openBook(book) {
            // console.log("Opening TendrilComponent", book);
            //If book as 'bookData' (Tendril Product Article), pass tendril product id
            // let productId = book?.tendrilProduct?._id;
            // if (productId) {
            //   this.$router.push({ path: `/library/${productId}` });
            // }
            if (book?.tendrilProduct || book?.interactiveStoryLink) {
                this.$router.push({ path: `/library/${book?.title}` });
            } else {
                console.warn("Product ID not found!", book);
            }
        },
    },
    mounted() {
        //   console.log(`Mounted (${this.item.title}):`, this.item);
    },
};
</script>

<style lang="scss">
.book-cover {
    object-fit: contain;
}
.book-img {
    max-width: 140px;
    width: 100%;
}
@media (max-width: 140px) {
    .book-img {
        max-width: none;
    }
}

.library-book {
    // max-width: min(100%, 140px);
    // @media (min-width: 140px) {
    //   width: 140px;
    // }

    opacity: 0.85;
    &:hover {
        z-index: 1;
        scale: 1.3;
        opacity: 1;
    }
}
.v-dialog--active {
    border-radius: 24px !important;
}

.no-overflow {
    overflow-y: scroll;
    overflow-x: hidden;
}
.premium-icon {
    position: absolute !important;
    top: 5px;
    left: 5px;
    color: blue;
}
.premium-overlay {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.shadow {
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.25) 5px 4px 5px 0px;
}
.card-background {
    background-color: var(--v-primary-base);
}
</style>
