<template>
    <div v-if="!loading" class="outer">
        <section class="section-panel centered">
            <div class="constraint">
                <div>
                    <h1>Experience Library</h1>
                    <p class="font-xl mb-5">
                        Click, Read and Explore through our interactive social story experiences. These digital activities reduce anxiety in
                        everyday life by approaching challenges in an engaging new way and allow your child to learn independently. New
                        titles are added regularly.
                    </p>

                    <div class="visible-xs">
                        <div class="note">
                            Experiences are best designed and best viewed on a tablet or desktop device. Please view this page on a
                            different device or larger screen.
                        </div>
                    </div>
                    <div class="">
                        <div class="mx-auto py-4" v-for="(chunk, index) in chunkArray" :key="index">
                            <v-row no-gutters class="bookshelf mx-auto mt-5 pt-2 px-8 flex-shrik-1 flex-nowrap">
                                <!--style="flex: 1 0 18%;"-->
                                <v-col
                                    cols="3"
                                    class="my-3 pa-0 d-flex align-center justify-center"
                                    v-for="(item, index) in chunk"
                                    :key="index"
                                >
                                    <BookTile :ref="`${item.title}`" :item="item" />
                                </v-col>
                            </v-row>
                            <div class="bookshelf-base"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="d-flex full-width full-height flex-grow align-center justify-center" v-else>
        <v-progress-circular indeterminate size="35" color="white"></v-progress-circular>
    </div>
</template>
<script>
import BookTile from "@/components/library/book-dialog.vue";
import LibraryForm from "@/components/library/library-form.vue";
import { mapState } from "vuex";

export default {
    components: { BookTile, LibraryForm },
    meta: {},
    data() {
        return {
            tab: 0,
            collection: null,
            loading: true,
        };
    },
    methods: {},
    computed: {
        ...mapState(["assets", "libraryCollection"]),
        user() {
            return this.$app?.user;
        },
        books() {
            let books = this.collection?.data?.items || [];
            books.sort((a, b) => {
                if (a.isPremium && !b.isPremium) {
                    return 1;
                } else if (!a.isPremium && b.isPremium) {
                    return -1;
                } else {
                    return 0;
                }
            });

            return books;
        },
        chunkArray() {
            let chunked = [];
            for (let i = 0; i < this.books.length; i += 4) {
                const chunk = this.books.slice(i, i + 4);
                console.log(chunk);
                chunked.push(chunk);
            }
            return chunked;
        },
    },
    async mounted() {
        //This gets the 'library collection' article
        this.collection = await this.$fluro.content.get("643e0266b664306ce6d0b73e");

        // console.log("User verifieed", this.user?.verified);

        this.loading = false;

        this.$nextTick(() => {
            //Open book dialog if passed via params
            if (this.$route?.params?.book) {
                this.$refs[`${this.$route.params.book}`][0].dialog = true;
            }
        });
    },
};
</script>
<style scoped lang="scss">
.outer {
    background-color: #e0eced;
}

.note {
    font-weight: bold;
    background: #000;
    color: #fff;
    display: block;
    padding: 1em;
    font-size: 1.2em;
    border-radius: 1em;
}
</style>
<style lang="scss">
.bookshelf {
    background-color: white;
    border-radius: 25px 25px 0px 0px;
}

.bookshelf-base {
    height: 10px;
    background-color: rgba(#000, 0.1);
}

/////////////////////////////////////
</style>
